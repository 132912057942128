<template>
    <v-container class="pa-0" fluid>
        <v-row no-gutters>
            <v-col cols="12" class="mb-2">
                <span class="text-h5 primary-text--text pl-2">Bookings</span>
            </v-col>
            <v-col cols="12" class="mb-2">
                <v-container class="px-2 py-0 panel-light elevation-0" fluid>
                    <v-row dense>
                        <v-col v-if="$store.getters['cognito/userGroupAllowedWriteAccess'].includes('bookings')" cols="12" sm="3" align-self="center">
                            <v-menu v-if="selectedBookings.length || queryFiltersActive" offset-y v-model="adjustBookingsMenu">
                                <template v-slot:activator="{ on }">
                                    <v-btn 
                                        class="text-capitalize white--text button-accept pr-1" 
                                        small depressed
                                        v-on="on"
                                    >
                                        <span>Actions</span>
                                        <v-icon size="20" class="">mdi-menu-down</v-icon>
                                    </v-btn>
                                </template>
                                <v-list dense>                            
                                    <v-list-item>
                                        <v-list-item-content style="width: 100%" @click.capture="adjustBookingsMenu = false">
                                            <campaign-bookings-batch-adjust-dialog 
                                                :selectedBookings="selectedBookings"
                                                :searchQuery="query"
                                                :filtersActive="queryFiltersActive"
                                                :showCampaign="true"
                                                @close="refreshBookings"
                                            />
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-content style="width: 100%" @click.capture="adjustBookingsMenu = false">
                                            <campaign-bookings-batch-delete-dialog
                                                :selectedBookings="selectedBookings"
                                                :searchQuery="query"
                                                :filtersActive="queryFiltersActive"
                                                :showCampaign="true"
                                                @close="refreshBookings"
                                            />
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="7" sm="4" md="3" align-self="center" class="pb-2">
                            <app-filter-menu label="Sort" :items="ordering" :value="query.ordering" @update:value="setOrdering" />
                        </v-col>
                        <v-spacer class="hidden-sm-and-up"></v-spacer>
                        <v-col align-self="center" class="shrink">
                            <filter-bookings @filter="onFilter" />
                        </v-col>
                    </v-row>
                </v-container>    
            </v-col>
            <v-col>
                <v-data-table
                    v-model="selectedBookings"
                    class="elevation-1"
                    :headers="headers"
                    :items="bookingsSearch"
                    :options.sync="pagination"
                    :server-items-length="bookingsSearchCount"
                    :footer-props="{ itemsPerPageOptions: rowsPerPageItems }"
                    :loading="loading"
                    :show-select="$store.getters['cognito/userGroupAllowedWriteAccess'].includes('bookings')"
                    mobile-breakpoint="0"
                >
                    <template v-slot:item="props">
                        <router-link custom v-slot="{ navigate }" :to="{ name: 'BookingById', params: { bid: props.item.id } }">
                            <tr @click="navigate">                                
                                <td v-if="$store.getters['cognito/userGroupAllowedWriteAccess'].includes('bookings')">
                                    <v-simple-checkbox :value="props.isSelected" @input="props.select($event)"></v-simple-checkbox>
                                </td>
                                <td>
                                    <!-- <router-link :to="{ name: 'CampaignById', params: { id: props.item.campaign.id } }">{{ props.item.campaign.name }}</router-link> -->
                                    {{ props.item.campaign.name }}
                                </td>
                                <!-- <td class="hidden-xs-only">
                                    <v-row align="center"> -->
                                        <!-- <v-flex shrink pr-1><v-img :src="require('@/assets/displayunit-withlogo-white_thumbnail.png')" width="24" height="40" contain /></v-flex> -->
                                        <!-- <v-col><router-link :to="{ name: $store.state.app.names.locations.route + 'Instance', params: { id: props.item.location.id } }">{{ props.item.location.name }}</router-link></v-col>
                                    </v-row>                        
                                </td> -->
                                <td class="hidden-xs-only">{{ props.item.location.name || '---' }}</td>
                                <td class="pr-0">{{ props.item.start_date | prettifyDate }} - {{ props.item.end_date | prettifyDate }}</td>
                                <td class="hidden-sm-and-down">{{ props.item.spot_count }}x{{ props.item.item_duration }}s</td>
                                <td class="hidden-sm-and-down">
                                    <app-row-bar-graph :decimalPercentage="props.item.media_coverage / 100" emptyColour="expired_status" :alertValue="true" />
                                </td>
                                <td class="hidden-sm-and-down">
                                    <app-row-bar-graph :decimalPercentage="props.item.actual_plays / props.item.planned_plays" rounding="2">
                                        <template v-slot:tooltip v-if="props.item.planned_plays || props.item.actual_plays">
                                            <div class="text-caption secondary-text--text text--darken-3">{{ props.item.planned_plays | prettifyPlays }} planned</div>
                                            <div class="text-caption secondary-text--text text--darken-3">{{ props.item.actual_plays | prettifyPlays }} actual</div>
                                        </template>
                                    </app-row-bar-graph>
                                </td>
                                <td 
                                    class="hidden-md-and-up pl-0" 
                                    :style="`width: 5%; border-right: 12px solid ${props.item.media_coverage < 100 ? '#c46868' : '#5bd49c'}`"
                                    @click.stop="props.expand(!props.isExpanded)"
                                >
                                    <v-btn icon small class="ma-0">
                                        <v-icon color="primary">{{ props.isExpanded ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </router-link>
                    </template>

                    <template v-slot:expanded-item="props">
                        <td :colspan="headers.length" v-if="$vuetify.breakpoint.smAndDown">
                            <v-card class="elevation-0 grey lighten-5">
                                <v-divider class="secondary lighten-5"></v-divider>
                                <v-container class="pa-0" fluid>
                                    <v-row class="px-6 py-2" dense>
                                        <v-col cols="12" class="hidden-sm-and-up">
                                            <div class="text-caption grey--text">{{ $store.state.app.names.locations.label.singular }}</div>
                                            {{ props.item.location.name }}
                                        </v-col>
                                        <v-col cols="6" sm="4">
                                            <div class="text-caption grey--text">Spot Count</div>
                                            {{ props.item.spot_count }}
                                        </v-col>
                                        <v-col cols="6" sm="8">
                                            <div class="text-caption grey--text">Spot Duration</div>
                                            {{ props.item.item_duration }} sec.
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <div class="text-caption grey--text">Projected SOV</div>
                                            <div v-if="props.item.location.playlist_policy">
                                                {{ (props.item.item_duration * props.item.spot_count) / props.item.location.playlist_policy.loop_duration | prettifyPercentage(1) }}
                                            </div>
                                            <div v-else>---</div>
                                        </v-col>
                                        <v-col cols="6" sm="4">
                                            <div class="text-caption grey--text">Creative Coverage</div>
                                            <app-row-bar-graph :decimalPercentage="props.item.media_coverage / 100" emptyColour="expired_status" :alertValue="true" />
                                        </v-col>
                                        <v-col cols="6" sm="4">
                                            <div class="text-caption grey--text">Delivery (plays)</div>
                                            <app-row-bar-graph :decimalPercentage="props.item.actual_plays / props.item.planned_plays" rounding="2">
                                                <template v-slot:value-below v-if="props.item.planned_plays || props.item.actual_plays">
                                                    <div class="text-caption secondary-text--text">
                                                        <span class="text-no-wrap">{{props.item.planned_plays | prettifyPlays }} planned</span>
                                                        / 
                                                        <span class="text-no-wrap">{{ props.item.actual_plays | prettifyPlays }} actual</span>
                                                    </div>
                                                </template>
                                            </app-row-bar-graph>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-divider class="mt-2 secondary lighten-2"></v-divider>
                            </v-card>
                        </td>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { prettifyDate, prettifyPercentage, prettifyPlays } from '@/filters/filters'

export default {
    components: {
        AppFilterMenu: () => import('@/components/app/AppFilterMenu'),
        FilterBookings: () => import('@/components/app/FilterBookings'),
        CampaignBookingsBatchAdjustDialog: () => import('@/components/CampaignBookingsBatchAdjustDialog'),
        CampaignBookingsBatchDeleteDialog: () => import('@/components/CampaignBookingsBatchDeleteDialog'),
        AppRowBarGraph: () => import('@/components/app/AppRowBarGraph')
    },
    filters: {
        prettifyDate,
        prettifyPercentage,
        prettifyPlays
    },
    data () {
        return {
            adjustBookingsMenu: false,
            selectedBookings: []
        }
    },
    computed: {
        ...mapState(['loading', 'rowsPerPageItems']),
        ...mapState('bookings', ['ordering', 'headers', 'query']),
        ...mapGetters('bookings', ['bookingsSearch', 'bookingsSearchCount', 'queryFiltersActive']),
        pagination: {
            get () { return this.$store.state.bookings.pagination },
            set (value) { this.setPagination(value) }
        }
    },
    methods: {
        ...mapMutations('bookings', ['setQuerySearch', 'setBookingsSearch']),
        ...mapActions('bookings', ['getBookingsBySearch', 'setPagination', 'setOrdering']),
        refreshBookings (clearSelected) {
            if ( clearSelected ) this.selectedBookings = []
            this.getBookingsBySearch({ params: this.query })
        },
        onFilter () {
            this.setPagination(Object.assign({}, { ...this.pagination }, { page: 1 }))
        }
    },
    watch: {
        query: {
            immediate: true,
            deep: true,
            handler (to) {
                this.getBookingsBySearch({ params: to })
            }
        }
    },
    destroyed () {
        this.setBookingsSearch(null)
    }
}
</script>
